define('settings',{
	debug: true,

	injectClasses: true,
	classPrefix: {
		parameter: '-',
		value: '-',
		state: 'is-',
		feature: 'has-',
		featureless: 'has-no-'
	},

	animate: {
		duration: '.2s',
		timing: 'ease-in-out'
	}
});
