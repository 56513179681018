define('util',['require','util/acceptValue','util/camelcase','util/element','util/prefixClass','util/support','util/frame'],function(require)
{
	return {
		acceptValue:    require('util/acceptValue'),
		camelcase:      require('util/camelcase'),
		element:        require('util/element'),
		prefixClass:    require('util/prefixClass'),
		support:        require('util/support'),
		frame:          require('util/frame')/*,
		fsm:            require('util/fsm'),
		propertyParser: require('util/propertyParser')*/
	};
});
