define('util/element',[],function()
{
	return {

		/**
		 * Is the element visible?
		 * Checks the dimensions of an element to be above zero.
		 *
		 * @param el
		 * @returns {boolean}
		 */
		isVisible: function(el)
		{
			return !!(el.offsetWidth && el.offsetHeight);
		},

		/**
		 * Is the element on the DOM?
		 *
		 * @param el
		 * @returns {*}
		 */
		inDOM: function(el)
		{
			return document.body.contains(el);
		},

		/**
		 *
		 * @param el
		 */
		isDisplayed: function(el)
		{
			return this.inDOM(el) && window.getComputedStyle(el).display != 'none';
		},

		/**
		 * Causes a browser repaint centered around the element
		 *
		 * @param el
		 */
		repaint: function(el)
		{
			el.offsetWidth;
		}
	};
});
