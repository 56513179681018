/*! Spicy Web | spicyweb.com.au */

require(['libs/jquery', 'settings', 'util/prefixClass', 'dom', 'util'], function($, settings, prefixClass)
{
	this.hydro = {
		version: '1.0.0'
	};

	// Set the document state to ready upon DOM ready
	if(settings.injectClasses)
	{
		$(function()
		{
			$(document.documentElement)
				.removeClass(prefixClass('state', 'loading'))
				.addClass(prefixClass('state', 'ready'));
		});
	}
});
